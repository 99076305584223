


import React from "react";

const Housekeeping = ({visibility, img}) => {
  const handleVisibility = () => {
    visibility();
  };
    return (   
        <>  
          <div className="work-section-info-card">
          <div className="inner-box-card">
            <div className="info-boxes-img-container">
              <img src={img} alt="" />
            </div>
            <div id="Des-card">
            <h2>Housekeeping Services</h2>
            <p>Kanha Enterprises offers a comprehensive suite of
              housekeeping services, ensuring spaces are not only
              clean but also aesthetically pleasing. From routine
              cleaning schedules to specialized deep cleaning, our
              service caters to the diverse needs of residential and
              commercial spaces.<br/>
              <br/></p>
              <u>SERVICE HIGHLIGHTS</u> 
            <p>
            Cleaning of staircases, floors, toilets and glass. <br/>
            Dusting, brass polishing and pantry cleaning.<br/>
            Litter removal and waste management.<br/>
            Room freshening and more.</p>
          </div>
          </div>
          <button className="learn-more-button-card" onClick={handleVisibility}>OK</button>
      </div>
      </>
    );
};

export default Housekeeping;
