import React from "react";
import phone from "../Assets/phone.jpg";
import map from "../Assets/map copy.png";

const Contact = () => {
  const handleEmailClick = () => {
    window.location.href = 'mailto:info@kanhaenterprises.in?';
  };
  return (
    <>
    <div className="contact-page-wrapper" id="Footer">
      <h1 className="primary-heading">Get in Touch</h1>
      <h1 className="primary-heading">Let Us Help You</h1>
      <div className="contact-form-container">
        <p>info@kanhaenterprises.in</p>
        <button onClick={handleEmailClick} className="secondary-button">Mail Us</button>
      </div>
      <div className="last">
      <div className="contact-form2-container">
        <p>+91 7011774088 <br/><br/>+91 8383943094 </p>
        <img src={phone} alt="Phone"/>
      </div>

      <div className="contact-location-container">
        <p><b><u>Corporate Office:</u></b> <br/>No 68, Ground Floor,
        Raja Garden, New Delhi 110015<br/>
        <br/>
        <br/>
        <b><u>Head Office:</u></b> <br/>I-7/45, Upper Ground floor,
        Sector-16, Rohini, New Delhi-110085</p>
        <img src={map}  alt="Location"/>
      </div>
    </div>
  </div>
  </>
  );
};

export default Contact;
