import React from "react";

const Pantry = ({visibility, img}) => {
  const handleVisibility = () => {
    visibility();
  };
    return (   
        <>  
        
          <div className="work-section-info-card">
          <div className="inner-box-card">
            <div className="info-boxes-img-container">
              <img src={img} alt="" />
            </div>
            <div id="Des-card">
            <h2>Security Services</h2>
            <p>In addition to our commitment to cleanliness, we offer
              top-notch security services. Kanha Enterprises provide
              trained security guards to ensure a safe and secure
              environment for residential and commercial properties.<br/>
              <br/></p>
              <u>SERVICE HIGHLIGHTS</u>
            <p>
            Provision of trained security guards for a safe and
              secure environment.  <br/>
             Stringent security protocols to safeguard residential
              and commercial properties.</p>
          </div>
          </div>
          <div className="end">
          <button className="learn-more-button-card" onClick={handleVisibility}>
            OK
          </button>
          </div>
      </div>
      </>
    );
};

export default Pantry;