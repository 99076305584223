import React from "react";
import decathlon from "../Assets/DECATHLON.gif";
import ICICI from "../Assets/ICICI.gif";
import jeevan from "../Assets/Jeevan.png";
import tata from "../Assets/TATA.png";
import anov from "../Assets/anov.jpg";
import saa from "../Assets/saawariya.png";
import airtel from "../Assets/airtel.png";
import Dsarkar from "../Assets/Delhi_sarkar.png";
import sri from "../Assets/sri.jpeg";
import dd from "../Assets/DD.png";
import op from "../Assets/OP.png";
import jmd from "../Assets/JMD.png";
import aoksha from "../Assets/aoksha.png";
import param from "../Assets/param.jpg";
import p1 from "../Assets/pacific1.png";
import p2 from "../Assets/pacific2.png";

const Partner = () => {
  return (
    <>
    <div className="work-section-wrapper" id="Testimonials">
      <div className="work-section-top">
        <h1 className="primary-heading">Our Partners</h1>
      </div>
      </div>
      <div class="wrapper">
  <div class="item item1"><img src={ICICI}  alt="" ></img></div>
  <div class="item item2"><img src={decathlon}  alt="" ></img></div>
  <div class="item item3"><b>Haryana Bhawan</b></div>
  <div class="item item4"><img src={anov}  alt="" ></img></div>
  <div class="item item5"><img src={saa}  alt="" ></img></div>
  <div class="item item6"><img src={tata}  alt="" ></img></div>
  <div class="item item7"><img src={jeevan}  alt="" ></img></div>
  <div class="item item8"><img src={airtel}  alt="" ></img></div>
  <div class="item item9"><b>GOVT. SARVODAYA KANYA VIDYALAYA</b></div>
</div>
<div class="wrapper">
  <div class="itemR item1"><img src={airtel}  alt="" ></img></div>
  <div class="itemR item2"><b>Haryana Bhawan</b></div>
  <div class="itemR item3"><b>DELHI DISTRICT COURTS</b></div>
  <div class="itemR item4"><img src={Dsarkar}  alt="" ></img></div>
  <div class="itemR item5"><b>KARNATAKA BHAVAN</b></div>
  <div class="itemR item6"><img src={dd}  alt="" ></img></div>
  <div class="itemR item7"><img src={op}  alt="" ></img></div>
  <div class="itemR item8"><img src={sri}  alt="" ></img></div>
</div>
<div class="wrapper">
  <div class="item item1"><img src={jmd}  alt="" ></img></div>
  <div class="item item2"><b>PUNJAB SARKAR</b></div>
  <div class="item item3"><img src={aoksha}  alt="" ></img></div>
  <div class="item item4"><b>KARNATAKA BHAVAN</b></div>
  <div class="item item5"><img src={param}  alt="" ></img></div>
  <div class="item item6"><img src={p1}  alt="" ></img></div>
  <div class="item item7"><img src={p2}  alt="" ></img></div>
</div>
</>
    
  );
};

export default Partner;
