import React from "react";

const DeepCleaning = ({visibility, img}) => {
  const handleVisibility = () => {
    visibility();
  };
    return (  
          <div className="work-section-info-card">
          <div className="inner-box-card">
            <div className="info-boxes-img-container">
              <img src={img} alt="" />
            </div>
            <div id="Des-card">
            <h2>Deep Cleaning Services</h2>
            <p>Our deep cleaning services go beyond the surface,
                focusing on eradicating hidden germs and allergens.
                Restrooms upkeep, maintenance cleaning, and
                specialized services contribute to an environment that is
                both clean and healthy. This service also includes Carpet
                Shampoo, Chair Shampoo, Sofa Cleaning and Binds
                Cleaning.<br/>
              <br/></p>
              <u>SERVICE HIGHLIGHTS</u>
            <p>
            Scrubbing of floors, wax polishing and window
            cleaning. <br/>
            Removal of cobwebs and scrubbing of walls. <br/>
            Specialized services for restrooms upkeep and
            maintenance cleaning.</p>
          </div>
          </div>
          <div className="end">
          <button className="learn-more-button-card" onClick={handleVisibility}>
            OK
          </button>
          </div>
      </div>
    );
};

export default DeepCleaning;
