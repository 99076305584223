import React from "react";
import { AiFillStar } from "react-icons/ai";

const Testimonial = () => {
  return (
    <div className="work-section-wrapper" id="Testimonials">
      <div className="work-section-top">
        <p className="primary-subheading">Testimonial</p>
        <h1 className="primary-heading">What They Are Saying</h1>
      </div>
      <div id="review">
      <div className="testimonial-section-bottom">
        <h2>Mr. Sanjeev</h2>
        <br/>
        <div className="testimonials-stars-container">
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
        </div>
        <p>
        <b> Service provided - </b>Pest Control and Termite Services <br/>
<b>Booking Process - </b>Booking was seamless, with prompt
responses and easy scheduling.<br/>
<b>Arrival & Punctuality - </b>The team arrived on time which
was appreciated.<br/>
<b>Professionalism - </b>Professionalism was evident
throughout the service. The team was courteous and
knowledgeable.<br/>
<b>Quality of Service - </b>The pest control and termite
treatment were thorough, addressing all problem areas
effectively.<br/>
<b>Attention to detail - </b>They paid close attention to detail,
ensuring that no area was overlooked.<br/><br/>
        </p>
        
      </div>
      <div className="testimonial-section-bottom">
        <h2>Mrs. Kalpana</h2>
        <br/>
        <div className="testimonials-stars-container">
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
        </div>
        <p>
        <b>Service provided -</b>Deep Cleaning Service <br/>
        <b>Impressions and Feedback - </b>I recently hired Kanha
Enterprises for a deep cleaning service, and I'm highly
impressed. From booking to completion, their
professionalism shone through.<br/>
<b>Booking Process - </b>Smooth and hassle-free. Quick
responses and easy scheduling. <br/>
<b>Professionalism - </b>The cleaning crew was courteous and
respectful. <br/>
<b>Quality of Cleaning -</b>Thorough and meticulous. They
didn't miss a spot. <br/>
<b>Results - </b>My home feels rejuvenated and spotless.
Overall Experience - Exceptional service. I highly
recommend Kanha Enterprise.
        </p>
      </div>
      </div>
    </div>
  );
};

export default Testimonial;
