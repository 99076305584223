import React, { useState } from "react";

//import images
import HouseKeepingImg from "../Assets/HouseKeeping.jpg";
import DC from "../Assets/DeepCleaning.jpg";
import Sec from "../Assets/Security.jpg";
import S from "../Assets/Sanitization.jpg";
import P from "../Assets/Pantry.jpg";
import Pest from "../Assets/Pest.jpg";
import IT from "../Assets/image.png";
import ITimg from "../Assets/image.png";

//import Card Components
import Housekeeping from './cards/Housekeeping';
import Security from './cards/Security';
import DeepCleaning from './cards/DeepCleaning';
import Pantry from './cards/Pantry';
import PestControl from './cards/PestControl';
import Sanitization from './cards/Sanitization';
import ITsolution from './cards/ITsolution';

const Work = () => {
  const [houseKeepingCard, setHouseKeepingCard] = useState(false);
  const [deepCleaningCard, setDeepCleaningCard] = useState(false);
  const [securityCard, setSecurityCard] = useState(false);
  const [SanitizationCard, setSanitizationCard] = useState(false);
  const [PantryCard, setPantryCard] = useState(false);
  const [pestCard, setPestCard] = useState(false);
  const [ITsolutionCard, setITsolutionCard] = useState(false);

  const handleHouseKeeping = () => {
    setHouseKeepingCard(!houseKeepingCard);
  };
  const handleSecurity = () => {
    setSecurityCard(!securityCard);
  };
  const handleDeepCleaning = () => {
    setDeepCleaningCard(!deepCleaningCard);
  };
  const handlePantry = () => {
    setPantryCard(!PantryCard);
  };
  const handlePest = () => {
    setPestCard(!pestCard);
  };
  const handleSanitization = () => {
    setSanitizationCard(!SanitizationCard);
  };
  const handleITSolution = () => {
    setITsolutionCard(!ITsolutionCard);
  };

  return (
    <>
      {houseKeepingCard && (
        <div className="modal">
          <Housekeeping visibility={handleHouseKeeping} img={HouseKeepingImg} />
        </div>
      )}
      {securityCard && (
        <div className="modal">
          <Security visibility={handleSecurity} img={Sec} />
        </div>
      )}
      {deepCleaningCard && (
        <div className="modal">
          <DeepCleaning visibility={handleDeepCleaning} img={DC} />
        </div>
      )}
      {PantryCard && (
        <div className="modal">
          <Pantry visibility={handlePantry} img={P} />
        </div>
      )}
      {pestCard && (
        <div className="modal">
          <PestControl visibility={handlePest} img={Pest} />
        </div>
      )}
      {SanitizationCard && (
        <div className="modal">
          <Sanitization visibility={handleSanitization} img={S} />
        </div>
      )}
      {ITsolutionCard && (
        <div className="modal">
          <ITsolution visibility={handleITSolution} img={ITimg} />
        </div>
      )}
      <div className="work-section-wrapper" id="work">
        <div className="work-section-top">
          <h1 className="primary-heading_orange">Services Offered</h1>
        </div>
        <div className="work-section-bottom">
          <div className="work-section-info">
            <div className="inner-box">
              <div className="info-boxes-img-container">
                <img src={HouseKeepingImg} alt="Housekeeping" />
              </div>
              <div id="Des">
                <h2 id="Title">Housekeeping Services</h2>
                <p>
                  Cleaning of staircases, floors, toilets, and glass. <br />
                  Dusting, brass polishing, and pantry cleaning.<br />
                  Litter removal and waste management.<br />
                  Room freshening and more.
                </p>
              </div>
              <div className="end">
                <button className="learn-more-button" onClick={handleHouseKeeping}>
                  Know More
                </button>
              </div>
            </div>
          </div>
          <div className="work-section-info">
            <div className="inner-box">
              <div className="info-boxes-img-container">
                <img src={DC} alt="Deep Cleaning" />
              </div>
              <div id="Des">
                <h2>Deep Cleaning Services</h2>
                <p>
                  Scrubbing of floors, wax polishing, and window cleaning. <br />
                  Removal of cobwebs and scrubbing of walls. <br />
                  Specialized services for restrooms upkeep and maintenance cleaning.
                </p>
              </div>
              <div className="end">
                <button className="learn-more-button"  onClick={handleDeepCleaning}>
                  Know More
                </button>
              </div>
            </div>
          </div>
          <div className="work-section-info">
            <div className="inner-box">
              <div className="info-boxes-img-container">
                <img src={Sec} alt="Security" />
              </div>
              <div id="Des">
                <h2>Security Services</h2>
                <p>
                  Provision of trained security guards for a safe and secure environment. <br />
                  Stringent security protocols to safeguard residential and commercial properties.
                </p>
              </div>
              <div className="end">
                <button className="learn-more-button" onClick={handleSecurity}>
                  Know More
                </button>
              </div>
            </div>
          </div>
          <div className="work-section-info">
            <div className="inner-box">
              <div className="info-boxes-img-container">
                <img src={S} alt="Sanitization" />
              </div>
              <div id="Des">
                <h2>Sanitization</h2>
                <p>
                  Comprehensive hygiene solutions. <br />
                  Advanced techniques and eco-friendly products. <br />
                  Protection against harmful germs.
                </p>
              </div>
              <button className="learn-more-button" onClick={handleSanitization}>
                Know More
              </button>
            </div>
          </div>
          <div className="work-section-info">
            <div className="info-boxes-img-container">
              <img src={P} alt="Pantry" />
            </div>
            <div id="Des">
              <h2>Pantry Services</h2>
                <p>
                  Efficient management of pantry areas. <br />
                  Stocking and organizing pantry supplies. <br />
                  Regular monitoring and replenishment of items.
                </p>
            </div>
            <div className="end">
              <button className="learn-more-button" onClick={handlePantry}>
                Know More
              </button>
            </div>
          </div>
          <div className="work-section-info">
            <div className="info-boxes-img-container">
              <img src={Pest} alt="Pest Control" />
            </div>
            <div id="Des">
              <h2>Pest Control & Termite Services</h2>
              <p>
                Efficient pest elimination. <br />
                Environmentally conscious approaches. <br />
                Safeguarding against pests and termites.
              </p>
            </div>
            <div className="end">
              <button className="learn-more-button" onClick={handlePest}>
                Know More
              </button>
            </div>
          </div>
          <div className="Housekeeping-info">
            <div className="info-wide">
              <div className="info-boxes-img-container">
                <img src={IT} alt="IT Solutions" />
              </div>
              <div id="Des2">
                <h2>IT Solutions & Services</h2>
                <p>
                  CAMERA AMC <br />
                  COMPUTER STATIONARY<br />
                  Photo Copier Machine<br />
                  Networking<br />
                  All Software Hardware Networking
                </p>
              </div>
            </div>
            <div className="end">
              <button className="learn-more-button" onClick={handleITSolution}>
                Know More
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Work;
