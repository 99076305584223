import "./App.css";
import Home from "./Components/Home";
import About from "./Components/About";
import Services from "./Components/Services";
import Video from './Components/Video'
import Testimonial from "./Components/Testimonial";
import Partner from "./Components/Partner";
import Contact from "./Components/Contact";

function App() {
  return (
    <div className="App">
      <Home />
      <About />
      <Services />
      <Video />
      <Testimonial />
      <Partner />
      <Contact />
    </div>
  );
}

export default App;
