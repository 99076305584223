import React from "react";

const Pantry = ({visibility, img}) => {
  const handleVisibility = () => {
    visibility();
  };
    return (   
        <>  
        
          <div className="work-section-info-card">
          <div className="inner-box-card">
            <div className="info-boxes-img-container">
              <img src={img} alt="" />
            </div>
            <div id="Des-card">
            <h2>Pantry Services</h2>
            <p>Kanha Enterprises offers professional pantry services,
              managing and organizing spaces efficiently. With a
              commitment to quality and customer satisfaction, our
              trained staff ensures timely restocking and inventory
              management, creating well-maintained and organized
              pantry areas.<br/>
              <br/></p>
              <u>SERVICE HIGHLIGHTS</u>
            <p>
            Efficient management of pantry areas. <br/>
            Stocking and organizing pantry supplies. <br/>
            Regular monitoring and replenishment of items.</p>
          </div>
          </div>
          <div className="end">
          <button className="learn-more-button-card" onClick={handleVisibility}>
            OK
          </button>
          </div>
      </div>
      </>
    );
};

export default Pantry;
