import React from "react";

const Video = () => {
  return (
    <div className="work-section-wrapper" id="Testimonials">
      <div className="work-section-top">
        <h1 className="primary-heading">Services at a Glance</h1>
      <iframe title="service-video" width="750px" height="423" src="https://stkenterprises.blob.core.windows.net/kenterprise-work/Videos/KEnterprise.mp4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  );
};

export default Video;
