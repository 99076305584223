import React from "react";

const ITsolution = ({visibility, img}) => {
  const handleVisibility = () => {
    visibility();
  };
    return (   
        <>  
          <div className="work-section-info-card-long">
          <div className="inner-box-card-long">
            {/* <div className="info-boxes-img-container">
              <img src={img} alt="" />
            </div> */}
            <h1>IT Solutions & Services</h1>
            <div id="Des-card">
            <b>1 - CAMERA AMC</b>
                 <br/>
            <p>Regular inspections and maintenance.  <br/>
              Swift response to technical issues. <br/>
              Extended equipment lifespan.
             <br/><br/>
             <b>2 - COMPUTER STATIONARY</b><br/>
             Premium quality supplies.<br/>
             Tailored solutions for diverse needs.<br/>
             Prompt delivery and replenishment.<br/><br/>
             <b>3 - Photo Copier Machine</b><br/>
             Proactive maintenance and repairs. <br/>
             Timely replenishment of consumables.<br/>
             Reliable technical assistance.<br/><br/>
             <b>4 - Networking</b><br/>
             <b>5 - All Software Hardware Networking </b>
               <br/>
            Expert setup and configuration.  <br/>
            Seamless integration with existing systems.  <br/>
            Continuous monitoring for optimal performance.<br/>
             </p>
              
          </div>
          </div>
          <div className="end">
          <button className="learn-more-button-card" onClick={handleVisibility}>
            OK
          </button>
          </div>
      </div>
      </>
    );
};

export default ITsolution;