import React from "react";

const PestControl = ({visibility, img}) => {
  const handleVisibility = () => {
    visibility();
  };
    return (   
        <>  
        
          <div className="work-section-info-card">
          <div className="inner-box-card">
            <div className="info-boxes-img-container">
              <img src={img} alt="" />
            </div>
            <div id="Des-card">
            <h2>Pest Control & Termite Services</h2>
            <p>Kanha Enterprises offers tailored Pest control and
              Termite services, ensuring effective solutions through
              thorough inspections and advanced techniques. Our
              commitment to quality and customer satisfaction
              guarantees timely treatments and personalized
              recommendations for long-term pest management,
              safeguarding properties from damage and
              contamination.<br/>
              <br/></p>
              <u>SERVICE HIGHLIGHTS</u> 
            <p>
            Efficient pest elimination.  <br/>
            Environmentally conscious approaches.  <br/>
            Safeguarding against pests and termites.</p>
          </div>
          </div>
          <div className="end">
          <button className="learn-more-button-card" onClick={handleVisibility}>
            OK
          </button>
          </div>
      </div>
      </>
    );
};

export default PestControl;
