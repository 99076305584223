import React from "react";
import AboutBackgroundImage from "../Assets/Back2.png";

const About = () => {
  return (
    <>
    <div className="about-section-container" id="About">
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        <h6 className="primary-heading">
          About Our Company
        </h6>
        <p className="primary-text">
        <b>  Kanha Enterprises</b>  delivers premier Housekeeping, Deep
            Cleaning, Security and Manpower Outsourcing services,
            driven by a commitment to excellence. Our bonded, insured
            and highly trained professionals provide flexible service
            seven days a week, ensuring top quality results. With
            competitive rates and on-site supervision included, we
            prioritize customer satisfaction and productivity. 
        </p>
        <p className="primary-text">
          Trust <b>  Kanha Enterprises</b> for exceptional cleaning services that elevate
          standards and create healthy environments.
          <br/>
          <br/>
        </p>
      </div>
      
    </div>
    <div className="vm">
    <h1>
    Vision
  </h1>
  <p>
  <b>  Kanha Enterprises</b> is more than just a cleaning service; we’re your
holistic solution provider, offering pristine hygiene and robust security.
Our vision is to set new standards in excellence, creating spaces of well-
being and safety. We’re committed to surpassing expectations and
leading in sustainability, aiming for spaces that inspire and protect. With
integrity and dedication, we’re redefining facility management, ensuring
cleanliness and precision in every aspect. Join us in shaping a future
where spaces reflect our unwavering pursuit of perfection.
<br/>
<br/>
  </p>
  <h1>
    Mission
  </h1>
  <p>
  Trust <b>  Kanha Enterprises</b> is dedicated to elevating cleanliness, security and
well-being in all spaces. With a focus on innovation and excellence, we
exceed expectations, pioneer sustainability and lead in facility
management. Our mission is to create inspiring environments that
promote health, safety and tranquility leaving a lasting impact on our
industry and the world. Join us in our commitment to perfection and a
brighter future.
  </p>
  </div>
  </>
  );
};

export default About;
