import React from "react";

const Sanitization = ({visibility, img}) => {
  const handleVisibility = () => {
    visibility();
  };
    return (   
        <>  
        
          <div className="work-section-info-card">
          <div className="inner-box-card">
            <div className="info-boxes-img-container">
              <img src={img} alt="" />
            </div>
            <div id="Des-card">
            <h2>Sanitization</h2>
            <p>Kanha Enterprises excels in sanitization services,
                employing advanced techniques and eco-friendly
                products to ensure through cleanliness. With a focus
                on protecting against harmful germs, our dedicated
                team delivers tailored solutions for both residential
                and commercial spaces, creating hygienic
                environments for all.</p>
              <u>SERVICE HIGHLIGHTS</u> 
            <p>
            Comprehensive hygiene solutions. <br/>
              Advanced techniques and eco-friendly products. <br/>
              Protection against harmful germs.</p>
          </div>
          </div>
          <div className="end">
          <button className="learn-more-button-card" onClick={handleVisibility}>
            OK
          </button>
          </div>
      </div>
      </>
    );
};

export default Sanitization;
